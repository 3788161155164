import React from "react";
import { useFlashMessages } from "../utils/flash-messages";
import {
  AuthError,
  ClientError,
  NetworkError,
  ServerError,
} from "../api/errors";
import { useAuthContext } from "../utils/authentication";
import config from "../config";
import { getTogglAccountsLoginUrl } from "../utils/toggl-accounts-urls";

export const useApiError = () => {
  const { addMessage } = useFlashMessages();
  const context = useAuthContext();

  return React.useCallback(
    (error) => {
      if (error instanceof ServerError) {
        addMessage({ type: "server_error" });
      } else if (error instanceof NetworkError) {
        addMessage({ type: "network_error" });
      } else if (error instanceof ClientError) {
        addMessage({ type: "client_error", error });
      } else if (error instanceof AuthError) {
        if (config.sharedAuthEnabled) {
          window.location.assign(getTogglAccountsLoginUrl());
        } else {
          context?.clearToken();
        }
      }
    },
    [addMessage]
  );
};
