import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import {
  ExpertQuestionBatch,
  ExpertStatus,
  useDeleteQuestionMutation,
  useExpertQuestionBatchesUnderReviewQuery,
  useQuestionBatchQuery,
  useSubmitQuestionBatchMutation,
} from "../../utils/graphql-queries";
import { Button, PlusWhiteIcon } from "@hundred5/design-system";
import { Divider } from "../common";
import { useRouter } from "next/router";

import {
  ConfirmModal,
  QuestionBatchHeader,
  QuestionBatchTable,
  QuestionReviewModal,
  ReviewCommentModal,
} from "..";
import { Row } from "react-table";
import { TableQuestion } from "../question-batch-table/question-batch-table";
import { countBy } from "lodash";
import { useFlashMessages } from "../../utils/flash-messages";
import { ViewMode } from "../../types/viewMode";
interface Props {
  id: string;
}

interface ReviewCommentModalStateProps {
  open: boolean;
  actionType: "submit" | "reject" | "";
}

export default function ViewBatch({ id }: Props) {
  const [deleteModalState, setDeleteModalState] = React.useState({
    id: "",
    open: false,
  });
  const [reviewModalState, setReviewModalState] = useState({
    id: "",
    open: false,
  });

  const [reviewCommentModalState, setReviewCommentModalState] = useState<
    ReviewCommentModalStateProps
  >({
    open: false,
    actionType: "",
  });

  const context = useMemo(
    () => ({ additionalTypenames: ["ExpertQuestion"] }),
    []
  );

  const router = useRouter();
  const { addMessage } = useFlashMessages();
  const [questionBatchQuery] = useQuestionBatchQuery({
    variables: { id: id },
    context,
  });

  //TODO: temporary solution, we need to add expertQuestionBatchReview query to schema to fetch review status
  const [
    expertQuestionBatchesUnderReviewQuery,
  ] = useExpertQuestionBatchesUnderReviewQuery();

  const [_, submitQuestionBatch] = useSubmitQuestionBatchMutation();
  const [__, deleteQuestion] = useDeleteQuestionMutation();

  const { data } = questionBatchQuery;
  const {
    data: expertQuestionBatchesUnderReviewData,
  } = expertQuestionBatchesUnderReviewQuery;
  const questionBatch = data?.expertQuestionBatch;
  const expert = data?.expert;
  const expertQuestionBatchesUnderReview =
    expertQuestionBatchesUnderReviewData?.expertQuestionBatches;
  const isUnderReview = expertQuestionBatchesUnderReview?.find(
    (batch) => batch.id === questionBatch?.id
  );

  const reviewModeOn = questionBatch?.expert.id !== expert?.id;
  const questionCountByStatus = countBy(
    questionBatch?.expertQuestions,
    "status"
  );

  const addQuestionDisabled =
    questionBatch?.status !== "in_progress" ||
    questionBatch?.expertQuestions.length === questionBatch?.requiredQuestions;
  const submitForReviewDisabled =
    questionCountByStatus.draft > 0 ||
    (questionBatch?.expertQuestions.length ?? 0) <
      (questionBatch?.requiredQuestions ?? 0) ||
    questionBatch?.status !== "in_progress";
  const reviewSubmitDisabled =
    (questionCountByStatus.submitted ?? 0) > 0 ||
    questionBatch?.status !== "under_review";

  useEffect(() => {
    if (reviewModeOn && !reviewSubmitDisabled)
      addMessage({ type: "question_batch_reminder" });
  }, [reviewModeOn, reviewSubmitDisabled]);

  if (!questionBatch || !expert || !expertQuestionBatchesUnderReview) {
    return null;
  }

  return (
    <>
      <QuestionBatchHeader
        viewMode={ViewMode.Expert}
        batchId={id}
        backHref="/"
      />
      <ContentContainer>
        {reviewModeOn ? (
          isUnderReview && (
            <ReviewButtons>
              <BatchButton
                size="small"
                subVariant="submit"
                onClick={() =>
                  setReviewCommentModalState({
                    open: true,
                    actionType: "submit",
                  })
                }
                disabled={
                  reviewSubmitDisabled ||
                  expert.status === ExpertStatus.Inactive
                }
                data-tooltip={
                  expert.status !== ExpertStatus.Inactive &&
                  reviewSubmitDisabled
                    ? `Please accept / reject all questions first before you can submit the question set`
                    : null
                }
              >
                Submit batch
              </BatchButton>
              <BatchButton
                size="small"
                subVariant="reject"
                onClick={() =>
                  setReviewCommentModalState({
                    open: true,
                    actionType: "reject",
                  })
                }
                disabled={expert.status === ExpertStatus.Inactive}
                data-tooltip={
                  expert.status !== ExpertStatus.Inactive
                    ? `"Reject the whole question set"`
                    : null
                }
              >
                Reject batch
              </BatchButton>
            </ReviewButtons>
          )
        ) : (
          <Buttons>
            <BatchButton
              size="small"
              onClick={() => router.push(`/batches/${id}/new`)}
              disabled={addQuestionDisabled}
            >
              <PlusWhiteIcon /> Add new question
            </BatchButton>{" "}
            <BatchButton
              size="small"
              variant="secondary"
              onClick={async () => {
                const result = await submitQuestionBatch({ id });

                if (result.error) return;
                addMessage({
                  type: "question_batch_submitted",
                  amountRequired: questionBatch.requiredQuestions,
                  skill:
                    questionBatch.expertChallenge.expertChallengeSkills[0].skill
                      .name,
                });
              }}
              disabled={submitForReviewDisabled}
              data-tooltip={
                submitForReviewDisabled &&
                questionBatch.status === "in_progress"
                  ? `Please change the status of all questions from Draft to Submitted before you can submit the batch for review`
                  : undefined
              }
              tooltipAlwaysOn={
                questionBatch.requiredQuestions ===
                questionBatch.expertQuestions.length
              }
            >
              Submit for review
            </BatchButton>
          </Buttons>
        )}

        <Divider />
        <QuestionBatchTable
          batch={questionBatch as ExpertQuestionBatch}
          getRowProps={(row: Row<TableQuestion>) => ({
            onClick: () => {
              if (reviewModeOn) {
                setReviewModalState({ id: row.original.id, open: true });
              } else {
                router.push(`/questions/${row.original.id}`);
              }
            },
            "data-href": `/questions/${row.original.id}`,
          })}
          deleteRow={
            questionBatch.status === "in_progress"
              ? (row: Row<TableQuestion>) =>
                  setDeleteModalState({ id: row.original.id, open: true })
              : undefined
          }
        />
      </ContentContainer>
      <ConfirmModal
        open={deleteModalState.open}
        action="Delete"
        onConfirm={() => {
          deleteQuestion({ id: deleteModalState.id });
          setDeleteModalState({ id: "", open: false });
        }}
        onClose={() => setDeleteModalState({ id: "", open: false })}
      >
        <h3>Are you sure?</h3>
        <p>Do you really want to delete this question?</p>
      </ConfirmModal>
      <QuestionReviewModal
        open={reviewModalState.open}
        id={reviewModalState.id}
        onClose={() => setReviewModalState({ id: "", open: false })}
      />
      <ReviewCommentModal
        open={reviewCommentModalState.open}
        id={id}
        actionType={reviewCommentModalState.actionType}
        onClose={() =>
          setReviewCommentModalState({ open: false, actionType: "" })
        }
      />
    </>
  );
}

const ContentContainer = styled.div`
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin: 30px 24px;
  padding: 20px 35px 35px 35px;
`;

const Buttons = styled.div`
  display: flex;
  button {
    margin-right: 15px;
    :last-child {
      margin-right: 0;
    }
  }
`;

const ReviewButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 15px;
    :first-of-type {
      margin-right: 0;
    }
  }
`;

interface BatchButtonProps {
  variant?: "secondary" | "primary";
  subVariant?: "submit" | "reject";
  tooltipAlwaysOn?: boolean;
}
const BatchButton = styled(Button)<BatchButtonProps>`
  position: relative;
  height: 30px;
  background: ${(props) =>
    props.subVariant === "submit"
      ? "#1DC488"
      : props.subVariant === "reject"
      ? "#EB5757"
      : null};

  :disabled {
    opacity: 100%;
    color: ${(props) => (props.variant === "secondary" ? "#bdbdbd" : "#fffff")};
    border-color: #bdbdbd;
    background-color: ${(props) =>
      props.variant === "secondary" ? "#fffff" : "#bdbdbd"};
  }

  &[data-tooltip]::after {
    opacity: ${(props) => (props.tooltipAlwaysOn ? 1 : 0)};
  }

  &[data-tooltip]:hover::after {
    opacity: 1;
  }
`;
