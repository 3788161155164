import styled from "@emotion/styled";
import { format, parseISO } from "date-fns";
import { FetchExpertQuery } from "../../utils/graphql-queries";
import capitalize from "lodash/capitalize";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Column, Row } from "react-table";
import { CheckmarkIcon, CriticalIcon, PencilIcon } from "../../icons";
import { ExpertQuestionBatchStatus } from "../../utils/graphql-queries";
import Table from "../table";
import { useStoreSortState } from "../table/utils";

const STATUS_ALIASES: { [key in ExpertQuestionBatchStatus]?: string } = {
  in_progress: "Started",
  under_review: "Under review",
};

interface Props {
  batches: TableBatchItem[];
  viewType: BatchSummaryViewType;
}

const statusIcons: {
  [index in ExpertQuestionBatchStatus]?: React.ReactNode;
} = {
  accepted: <CheckmarkIcon color="#1DC488" />,
  rejected: <CriticalIcon />,
  in_progress: <PencilIcon color="#828282" />,
};
export interface TableBatchItem {
  id: string;
  challengeName?: string;
  expertName?: string;
  status: ExpertQuestionBatchStatus;
  statusChangedAt: Date;
  isExpertPaid: boolean;
  isReviewerPaid: boolean;
  reviewers?: string[];
  isReviewer?: boolean;
  dueOn?: Date;
}

export enum BatchSummaryViewType {
  Expert,
  Challenge,
}

export default function BatchSummaries({ batches, viewType }: Props) {
  const router = useRouter();
  const [sortState, sortStateCallback] = useStoreSortState("question_batch", {
    id: "lastChanged",
    desc: true,
  });

  const nullableDateTimeSort = useMemo(
    () => (
      rowA: Row<TableBatchItem>,
      rowB: Row<TableBatchItem>,
      columnId: string
    ) => {
      const distantFuture = new Date(8640000000000);
      const dateA = rowA.values[columnId]
        ? rowA.values[columnId].getTime()
        : distantFuture;
      const dateB = rowB.values[columnId]
        ? rowB.values[columnId].getTime()
        : distantFuture;
      return dateA === dateB ? 0 : dateA > dateB ? 1 : -1;
    },
    []
  );

  const columns: Column<TableBatchItem>[] = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Challenge name",
        accessor: "challengeName",
      },
      {
        Header: "Expert",
        accessor: "expertName",
        style: { maxWidth: "200px", textOverflow: "ellipsis" },
      },
      {
        Header: "Reviewers",
        accessor: "reviewers",
        style: { maxWidth: "200px", textOverflow: "ellipsis" },
        formatValue: (row: TableBatchItem) => {
          return <>{row?.reviewers && row.reviewers.join("\n")}</>;
        },
      },
      {
        Header: "Is reviewer",
        accessor: "isReviewer",
        formatValue: (row: TableBatchItem) => (
          <>{row.isReviewer && statusIcons.accepted}</>
        ),
        style: { textAlign: "center" },
        sortType: "basic",
      },
      {
        Header: "Status",
        accessor: "status",
        formatValue: (row: TableBatchItem) => (
          <>
            {statusIcons[row.status]}
            {STATUS_ALIASES[row.status] || capitalize(row.status)}
          </>
        ),
      },
      {
        Header: "Status changed on",
        accessor: "statusChangedAt",
        formatValue: (row: TableBatchItem) =>
          format(row.statusChangedAt, "d MMM yyyy"),
        sortType: "datetime",
      },
      {
        Header: "Expert Paid",
        accessor: "isExpertPaid",
        formatValue: (row: TableBatchItem) => (
          <>{row.isExpertPaid && statusIcons.accepted}</>
        ),
        style: { textAlign: "center" },
        sortType: "basic",
      },
      {
        Header: "Reviewer Paid",
        accessor: "isReviewerPaid",
        formatValue: (row: TableBatchItem) => (
          <>{row.isReviewerPaid && statusIcons.accepted}</>
        ),
        style: { textAlign: "center" },
        sortType: "basic",
      },
      {
        Header: "Due on",
        accessor: "dueOn",
        style: { minWidth: "100px" },
        formatValue: (row: TableBatchItem) =>
          row.dueOn && format(row.dueOn, "d MMM yyyy"),
        sortType: nullableDateTimeSort,
      },
    ],
    []
  );

  const tableOptions = useMemo(() => {
    const hiddenColumns: Array<keyof TableBatchItem> =
      viewType === BatchSummaryViewType.Expert
        ? ["expertName", "reviewers"]
        : ["challengeName", "isReviewer"];

    return {
      initialState: {
        sortBy: sortState,
        hiddenColumns: hiddenColumns,
      },
      disableSortRemove: true,
      autoResetSortBy: false,
    };
  }, [sortState, viewType]);

  return (
    <Container>
      <Table
        columns={columns}
        tableOptions={tableOptions}
        data={batches}
        stateCallback={sortStateCallback}
        getRowProps={(row: Row<TableBatchItem>) => ({
          onClick: () => {
            router.push(`/admin/batches/${row.original.id}`);
          },
          "data-href": `/admin/batches/${row.original.id}`,
        })}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
`;
