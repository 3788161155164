import React, { useCallback } from "react";
import store from "store2";
import {
  logIn,
  recordAcceptingTermsAndConditions,
  signup,
} from "../api/authentication";
import { ApiClient, createClient } from "../api/client";
import { useCreateExpertMutation } from "./graphql-queries";
import { useRouter } from "next/router";
import config, { default as envConfig } from "../config";
import { useApiMutation } from "../hooks/api-query";
import { getTogglAccountsLoginUrl } from "./toggl-accounts-urls";

const STORE_KEY = "togglhire_auth_token";

interface ContextValue {
  token: string | null | undefined;
  setToken(token: string): void;
  clearToken(): void;
}

const AuthContext = React.createContext<ContextValue | null>(null);

export const StorageAuthProvider: React.FC = (props) => {
  const [token, setTokenState] = React.useState<string | null | undefined>(
    undefined
  );

  const setToken = React.useCallback((token: string) => {
    setTokenState(token);
    store.set(STORE_KEY, token);
  }, []);

  const clearToken = React.useCallback(() => {
    setTokenState(null);
    store.remove(STORE_KEY);
  }, []);

  const value = React.useMemo(() => {
    return { token, setToken, clearToken };
  }, [token, setToken, clearToken]);

  React.useEffect(() => {
    setTokenState(store.get(STORE_KEY) ?? null);
  }, []);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

interface MemoryProviderProps {
  token: string | null;
}

export const MemoryAuthProvider: React.FC<MemoryProviderProps> = (props) => {
  const [token, setTokenState] = React.useState<string | null>(props.token);

  const setToken = React.useCallback((token: string) => {
    setTokenState(token);
  }, []);

  const clearToken = React.useCallback(() => {
    setTokenState(null);
  }, []);

  const value = React.useMemo(() => {
    return { token, setToken, clearToken };
  }, [token, setToken, clearToken]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): ContextValue | null => {
  const context = React.useContext(AuthContext);

  if (context == null && !envConfig.sharedAuthEnabled) {
    throw new Error("Authentication provider is missing");
  }

  return context;
};

export const useAuthToken = (): string | null => {
  const context = useAuthContext();

  if (context?.token == null && !envConfig.sharedAuthEnabled) {
    throw new Error("Authentication token is missing");
  }

  return context?.token || null;
};

export const useApiClient = (): ApiClient => {
  const token = useAuthToken();
  return React.useMemo(() => createClient(token), [token]);
};

export const useLoggedIn = (): boolean | null => {
  const context = useAuthContext();
  if (envConfig.sharedAuthEnabled) return null;
  if (context?.token === undefined) return null;
  return context.token != null;
};

export const useIsAdminContent = (): boolean => {
  const router = useRouter();
  return /^\/admin/.test(router.route);
};

export type LoginFunction = (
  email: string,
  password: string,
  captchaToken: string
) => Promise<string>;

export const useLogin = (): LoginFunction => {
  const context = useAuthContext();

  return async (email, password, captchaToken) => {
    const token = await logIn(email, password, captchaToken);
    context?.setToken(token);
    return token;
  };
};

export type LogoutFunction = () => void;

export const useLogout = (): LogoutFunction => {
  const context = useAuthContext();
  const apiClient = useApiClient();
  const [logOut] = useApiMutation(apiClient.logout);
  return useCallback(async () => {
    if (config.sharedAuthEnabled) {
      await logOut();
      window.location.assign(getTogglAccountsLoginUrl());
    } else {
      context?.clearToken();
    }
  }, [context, logOut, config.sharedAuthEnabled]);
};

export type SignupFunction = (
  name: string,
  email: string,
  password: string,
  captchaSignupToken: string,
  captchaLoginToken: string
) => Promise<void>;

export const useSignup = (): SignupFunction => {
  const logIn = useLogin();
  const [_, createExpert] = useCreateExpertMutation();

  return async (
    name,
    email,
    password,
    captchaSignupToken,
    captchaLoginToken
  ) => {
    await signup(email, password, captchaSignupToken);
    const token = await logIn(email, password, captchaLoginToken);
    await recordAcceptingTermsAndConditions(token, "experts-october-2020");
    // Auth header must be manually specified here to deal with a race condition
    // with the GraphQL client during signup.
    await createExpert(
      { name: name },
      {
        fetchOptions: { headers: { Authorization: `Bearer ${token}` } },
      }
    );
  };
};
